/* width */
::-webkit-scrollbar {
  width: 10px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(255, 255, 255,0.0); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255,0.09); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


.logo {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotateY(360deg);
  }
}